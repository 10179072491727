<template>
  <div>
    <tab-bar-wrapper/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :columns="columns"
        :componentName="$options.name"
        :contextMenuItems="contextMenuItems"
        :filter="filter"
        :query="query"
        @ready="ready"
        @setComments="setComments"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <action-bar-wrapper
      :commentList="commentList"
      @saveComments="saveComments"
    />
    <v-dialog
      v-model="localDialog"
      persistent
      max-width="800px"
      lazy
    >
      <lock-reason
        v-if="localDialog"
        :nodes="selectedNodes"
        @close="resetEditables"
        @save="save"
        @saveLock="saveLock"
      >
      </lock-reason>
    </v-dialog>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import lockReason from '@/components/sheets/lockReason.vue'
import grid from '@/components/mixins/grid'
import columns from '@/components/mixins/columns'
import mutateItems from '@/api/graphql/mutationsJS/mutateItems'
import { GET_BUY_SELL_HISTORY, GET_ITEMS } from '@/api/graphql/Constants/Inventory'
import { COLUMNS__INVENTORY_ITEMS } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__INVENTORY_ITEMS } from '@/lib/agGridContextMenuConfigurations'
import { KB_SHORTCUT__INVENTORY_ITEMS } from '@/lib/agGridKbShortCuts'
import { GridKbEventHandler } from '@/lib/eventHandlers'

export default {
  name: 'item',
  mixins: [grid, columns, mutateItems],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'lock-reason': lockReason
  },
  data () {
    return {
      sheet: '',
      query: GET_ITEMS,
      bsQuery: GET_BUY_SELL_HISTORY,
      commentList: {
        'inventory_comment': {
          name: 'inventory_comment',
          label: 'Internal',
          text: '',
          editable: true,
          show: true
        },
        'system_logs': {
          name: 'system_logs',
          label: 'System',
          text: '',
          editable: false,
          show: true
        },
        'condition_comment': {
          name: 'condition_comment',
          label: 'Condition',
          text: '',
          editable: true,
          show: true
        },
        'lock_reason': {
          name: 'lock_reason',
          label: 'Lock',
          text: '',
          editable: false,
          show: false
        },
        'id': false
      },

      filter: [{ key: 'system__isnull', value: true }, { key: 'receive_status__isnull', value: false }],
      originalFilter: [{ key: 'system__isnull', value: true }, { key: 'receive_status__isnull', value: false }],

      localDialog: false,
      selectedNodes: [],

      columns: COLUMNS__INVENTORY_ITEMS,

      contextMenuItems: (params) => CONTEXT_MENU__INVENTORY_ITEMS(params, this.user.initials, this.contextMenuCallback)
      // end context menu

    }
  },
  methods: {
    resetEditables () {
      this.localDialog = false
      this.$store.dispatch('data/clearEditions')
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__INVENTORY_ITEMS(this.user.initials, this.contextMenuCallback), [])
    },

    contextMenuCallback (params) {
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    save () {
      this.resetEditables()
      this.gapi.redrawRows()
    },

    saveLock (comment) {
      this.commentList.lock_reason.show = true
      this.commentList.lock_reason.text = comment
    },

    lock (selectedNodes) {
      this.selectedNodes = selectedNodes
      this.setUpDialog('lock-reason')
    },

    cancel () {
      this.resetEditables()
    },

    setUpDialog (dialog) {
      this.localDialog = dialog
    },

    // Edit Button functions

    async unlock (selectedNodes) {
      for (const selectedNode in selectedNodes) {
        const node = selectedNodes[selectedNode]
        await this.unLockItem(node.data.id)
        node.data.locked = false
        node.data.lock_reason = ''
        this.commentList.lock_reason.text = ''
      }
      this.commentList.lock_reason.show = false
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Unlocked item successfully.',
        color: 'success',
        timeout: 2000,
        canClose: false,
        top: false
      })
      this.save()
    },

    isBuiltUp (params) {
      return typeof params.node.data.build_up_number === typeof 2
    },

    // View Button Functions
    showBSHistory (item) {
      this.$store.dispatch('grid/setBuySellPart', { pn: item.part.pn, id: item.part.id })
      this.$store.dispatch('grid/changeDialog', { app: 'topbar', component: 'buysellhistory' })
    }
  }
}
</script>
