<template>
  <v-card>
    <v-card-title class="primary white--text"><h3>Provide Lock Notes</h3></v-card-title>
    <v-card-text>
      <v-container grid-list-xl>
        <v-layout justify-center align-center>
          <v-flex>
            <v-text-field
              v-model="comment"
              id="lock-comment"
              no-resize
              label="Notes..."
              hint="Required"
              persistent-hint
              @keyup.enter="save"
              @keyup.esc="cancel"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <cancel-button class="pr-3" :success="success" @click="cancel"/>
      <submit-button class="pr-1" :loading="saving" :disabled="success" @click="save"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import mutateItems from '@/api/graphql/mutationsJS/mutateItems'
import CancelButton from '@/components/buttons/CancelButton'
import SubmitButton from '@/components/buttons/SubmitButton'

export default {
  name: 'lockReason',
  mixins: [mutateItems],
  components: {
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    nodes: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      comment: '',
      success: false,
      saving: false
    }
  },
  methods: {
    async save () {
      this.saving = true
      try {
        for (const node of this.nodes) {
          await this.lockItem(node.data.id, this.comment)
          node.data.locked = true
          node.data.lock_reason = this.comment
        }
        this.success = true
        this.$emit('saveLock', this.comment)
      } catch (error) {
      } finally {
        this.saving = false
      }
    },

    cancel () {
      this.comment = ''
      this.$emit('close')
    }
  },
  mounted () {
    this.$nextTick(() => {
      document.getElementById('lock-comment').focus()
    })
  }
}
</script>
